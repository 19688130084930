
<style scoped>
/*头部公共样式结束*/
/* .detect {
  margin-top: 200px;
} */
.detect >>> .el-card__header {
  border: none;
}
.detect .el-card__header .icon-fanhui-:before {
  margin-right: 10px;
}
.detect .back-btn {
  color: #333;
  margin-left: 65px;
  position: absolute;
  left: 0;
  top: 50px;
}
.card-title {
  font-size: 30px;
  /* line-height: 80px; */
  color: #333333;
  margin-top: 60px;
}
.card-content {
  width: 1116px;
  height: 350px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-around;
}
.detect .content {
  padding: 0 65px;
  box-sizing: border-box;
}
.detect .content .search-box {
  display: flex;
  justify-content: space-between;
}
/*头部公共样式结束*/

.detect .content .content-box {
  padding: 40px 0 70px;
}
.detect .content .content-box .card {
  /* width: 315px; */
  height: 315px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 60px 0 70px;
  box-sizing: border-box;
  cursor: pointer;
}
.detect .content .content-box .card:hover {
  box-shadow: 5px 5px 5px rgba(000, 000, 000, 0.2);
  opacity: 0.9;
}
.detect .content .content-box .card .title {
  font-size: 30px;
  font-weight: normal;
  line-height: 40px;
  color: #ffffff;
  margin: 0;
}
.detect .content .content-box .card .title:last-child{
    font-size:26px;
}
.detect .content .content-box .card .num span {
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.detect .content .content-box .card .num i {
  font-size: 26px;
  color: #ffffff;
}
.hid {
  background-color: #29c4d7;
}
.pg {
  background-color: #52c0f9;
}
.aid {
  background-color: #50dab6;
}
.line {
  width: 60px;
  height: 4px;
  background-color: #ffffff;
  border-radius: 2px;
}
</style>
<template>
  <div class="detect" id="container">
    <el-card>
      <div slot="header">
        <el-button
          class="back-btn iconfont icon-fanhui-"
          type="text"
          @click="backHome"
        >
          返回首页
        </el-button>
        <p class="card-title">我的检测</p>
      </div>
      <div class="content">
        <div class="search-box">
          <date-picker
            type="年"
            :dateValue="from_time"
            @dateChange="dateChange"
            @typeChange="typeChange"
          ></date-picker>
          <el-button
            style="width: 200px; text-align: left"
            icon="el-icon-search"
            size="small"
            @click.native="toSearchReport"
          >
            搜索报告
          </el-button>
        </div>
        <el-row class="content-box" type="flex" justify="space-around">
          <el-col :span="7" class="card hid" @click.native="toInfo('HID')">
            <div>
              <p class="title">人类传染性疾病</p>
              <span class="title">（HID）</span>
            </div>

            <!-- <div class="line"></div> -->
            <div class="num">
              <span>{{ HID }}</span>
              <i>例</i>
            </div>
          </el-col>
          <el-col :span="7" class="card pg" @click.native="toInfo('PG')">
            <div>
              <p class="title">药物基因组</p>
              <span class="title">（PG）</span>
            </div>
            <!-- <div class="line"></div> -->
            <div class="num">
              <span>{{ PG }}</span>
              <i>例</i>
            </div>
          </el-col>
          <el-col :span="7" class="card aid" @click.native="toInfo('AID')">
            <div>
              <p class="title">动物感染疾病</p>
              <span class="title">（AID）</span>
            </div>
            <!-- <div class="line"></div> -->
            <div class="num">
              <span>{{ AID }}</span>
              <i>例</i>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import { projectNameTotal } from "@/api/base";
import DatePicker from "@/components/DatePicker/DatePicker.vue";
export default {
  name: "detect",
  data() {
    return {
      PG: null,
      AID: null,
      HID: null,
      time_type: "3",
      from_time: null,
      to_time: null,
    };
  },
  components: {
    DatePicker,
  },
  created() {
    this.from_time = String(new Date().getFullYear());
    this.to_time = String(new Date().getFullYear());
    this.getList();
  },
  methods: {
    //按大类分组查询报告数
    async getList() {
      let { time_type, from_time, to_time } = this;
      let res = await projectNameTotal({
        time_type,
        from_time,
        to_time,
      });
      let list = res.data.records;
      let PG = list.filter((item) => item.patient_project_name == "PG");
      let HID = list.filter((item) => item.patient_project_name == "HID");
      let AID = list.filter((item) => item.patient_project_name == "AID");
      if (PG.length != 0) {
        this.PG = PG[0].count;
      } else {
        this.PG = 0;
      }
      if (HID.length != 0) {
        this.HID = HID[0].count;
      } else {
        this.HID = 0;
      }
      if (AID.length != 0) {
        this.AID = AID[0].count;
      } else {
        this.AID = 0;
      }
    },
    //返回首页
    backHome() {
      this.$router.back();
    },
    //跳转详情页
    toInfo(data) {
      this.$router.push({
        path: "/detectInfo",
        query: {
          title: data,
          time_type: this.time_type,
          from_time: this.from_time,
          to_time: this.to_time,
        },
      });
    },
    //跳转报告搜索
    toSearchReport() {
        let owner_phone = ''
        let operation_phone = sessionStorage.getItem('phoneNumber')

      this.$router.push({path:"/searchReport",query:{
              owner_phone,
          operation_phone
      }});
    },
    //时间类型选择
    typeChange(e) {
      this.time_type = e;
    },
    //时间选择器返回值 e.dateValue  e.selectValue
    dateChange(e) {
      if (!this.time_type) {
        this.time_type = "3";
      }
      this.from_time = e.from_time;
      this.to_time = e.to_time;
      this.getList();
    },
  },
};
</script>
